import React, { useState } from 'react';
import { InferType } from 'yup';
import { Input, TextArea, Label } from '../common/Form';
import Button from '../common/Button';
import { TrashCanIcon } from '../../assets/svgs/icons';
import { MediaLibraryInterface } from '../../types/MediaInterface';
import MediaLibraryModal from '../MediaLibraryModal';
import MediaPreview from '../MediaPreview';
import imageDisplay from '../../assets/images/ImageDisplay.png';
import { PressImageSchema } from '../../pages/MainContainer/PressPage/PressPage';

interface RestaurantPressArticleProps {
  formik: any;
  handleDeleteArticle: Function;
  showDeleteButton?: boolean;
  isLastArticle: boolean;
  index: number;
}

const RestaurantPressArticle: React.FC<RestaurantPressArticleProps> = ({
  formik,
  handleDeleteArticle,
  showDeleteButton,
  isLastArticle,
  index
}) => {
  const [openMediaLibraryModal, setOpenMediaLibraryModal] = useState<boolean>(false);
  const [image, setImage] = useState<InferType<typeof PressImageSchema>>(formik?.values?.articles?.[index]?.image);

  const handleImageUpload = (_media: MediaLibraryInterface[]) => {
    const uploadedImage: InferType<typeof PressImageSchema> = {
      mediaID: _media?.[0]?.mediaID,
      mediaURL: _media?.[0]?.mediaUrl,
      type: 'image'
    };
    formik.setFieldValue(`articles.${index}.image`, uploadedImage);
    setImage(uploadedImage);
  };

  const handleMediaLibraryRemoval = () => {
    formik.setFieldValue(`articles.${index}.image`, undefined);
    setImage(undefined);
  };

  const handleCloseMediaLibraryModal = () => {
    setOpenMediaLibraryModal(false);
  };

  return (
    <div className="press-article-container">
      <Input
        name={`articles.${index}.articleName`}
        label="Article Name"
        className="press-page-article-name-input"
        onBlur={formik.handleBlur}
        touched={formik.touched?.articles?.[index]?.articleName}
        onChange={formik.handleChange}
        value={formik.values?.articles?.[index]?.articleName}
        error={formik.errors?.articles?.[index]?.articleName}
      />
      <Input
        name={`articles.${index}.publication`}
        label="Publication"
        className="press-page-article-publication-input"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.articles?.[index]?.publication}
        touched={formik.touched?.articles?.[index]?.publication}
        error={formik.errors?.articles?.[index]?.publication}
      />
      <Input
        name={`articles.${index}.link`}
        label="Link"
        className="press-page-article-link-input"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.articles?.[index]?.link}
        touched={formik.touched?.articles?.[index]?.link}
        error={formik.errors?.articles?.[index]?.link}
      />

      <TextArea
        name={`articles.${index}.articleDescription`}
        label="Description"
        className="press-page-article-description-input"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.articles?.[index]?.articleDescription}
        touched={formik.touched?.articles?.[index]?.articleDescription}
        error={formik.errors?.articles?.[index]?.articleDescription}
      />
      <div className="press-page-article-photo">
        <Label label="Photo" />
        <div className="press-page-profile-photo-upload">
          <div className="media-container">
            {!image?.mediaID && (
              <Button className="select-media-button" onClick={() => setOpenMediaLibraryModal(true)}>
                <img className="media-upload-icon" src={imageDisplay} alt="Icon indicating upload" />
                <p>Upload Media</p>
              </Button>
            )}
            <MediaPreview
              media={image?.mediaID ? [{ mediaID: image.mediaID, mediaURL: image.mediaURL, type: 'image' }] : []}
              onUpdate={handleImageUpload}
              multiple={false}
            />
            {openMediaLibraryModal && (
              <MediaLibraryModal
                selectedMediaIDs={image?.mediaID ? [image?.mediaID] : []}
                onSelect={handleImageUpload}
                onRemove={handleMediaLibraryRemoval}
                onClose={handleCloseMediaLibraryModal}
                mediaTypeOverride="image"
              />
            )}
          </div>
        </div>
      </div>
      {showDeleteButton && !isLastArticle && (
        <Button className="delete-article-button" onClick={handleDeleteArticle}>
          <TrashCanIcon /> Delete Article
        </Button>
      )}
    </div>
  );
};
export default RestaurantPressArticle;
