import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { InferType, array, number, object, string } from 'yup';
import { toast } from 'react-toastify';
import { Input, TextArea, Label } from '../../../components/common/Form';
import Button from '../../../components/common/Button';
import imageDisplay from '../../../assets/images/ImageDisplay.png';
import { PlusIcon } from '../../../assets/svgs/icons';
import { MediaLibraryInterface, MenuItemMediaInterface } from '../../../types/MediaInterface';
import RestaurantAboutTeamMember from '../../../components/RestaurantAboutTeamMember';
import { displayLoading, hideLoading, useAsyncContext } from '../../../contexts/AsyncContext';
import WizardHeader from '../../../components/WizardHeader';
import { useRestaurantContext } from '../../../contexts/RestaurantContext';
import MediaPreview from '../../../components/MediaPreview/MediaPreview';
import MediaLibraryModal from '../../../components/MediaLibraryModal/MediaLibraryModal';
import { useMediaLibraryContext } from '../../../contexts/MediaLibraryContext';
import { createProfilePage, editProfilePage, getProfilePage } from '../../../api/profilePages';
import {
  CreateProfileSectionCardResponseInterface,
  CreateProfileSectionRequestInterface,
  CreateProfileSectionResponseInterface,
  ProfileCardInterface,
  ProfileSectionInterface
} from '../../../types/ProfileSectionInterface';
import {
  createProfileSection,
  createProfileSectionCard,
  deleteProfileSectionCard,
  editProfileSection,
  editProfileSectionCard,
  linkMediasToProfileSection,
  updateMediaForProfileSectionCard
} from '../../../api/profileSections';
import { ProfilePageInterface } from '../../../types/ProfilePageInterface';

export const TeamMembersSchema = object({
  id: number().default(0),
  teamName: string().default('').required('Name is required.'),
  teamTitle: string().default('').required('Title is required.'),
  teamDescription: string().default('').required('Description is required.')
});

export const RestaurantAboutPageSchema = object({
  sectionName: string().required('About section name is required.'),
  restaurantDescription: string().required('Description about your restaurant is required.'),
  teamMembers: array()
    .of(TeamMembersSchema)
    .default([
      TeamMembersSchema.cast({
        teamName: '',
        teamTitle: '',
        teamDescription: ''
      })
    ])
});

const AboutPage = () => {
  const [teamMemberImage, setTeamMemberImage] = useState<MenuItemMediaInterface[]>([]);
  const [aboutSectionPhotos, setAboutSectionPhotos] = useState<MenuItemMediaInterface[]>(null);
  const [openMediaLibraryModal, setOpenMediaLibraryModal] = useState<boolean>(false);
  const { loadMedia } = useMediaLibraryContext();
  const [isEdit, setIsEdit] = useState<boolean>(null);
  const [page, setPage] = useState<ProfilePageInterface>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleMediaUpdate = (mediaInvolved: MenuItemMediaInterface[]) => {
    setAboutSectionPhotos(mediaInvolved);
  };

  useEffect(() => {
    // only want to load the restaurants user media if manager page is being utilized
    // call to load media on initial load
    loadMedia();
  }, [loadMedia]);

  const { currentRestaurant } = useRestaurantContext();

  const { dispatch: asyncDispatch } = useAsyncContext();

  useEffect(() => {
    let canceled = false;
    if (currentRestaurant != null && isEdit == null) {
      const aboutPage = currentRestaurant.pages.find((_page) => _page.name === 'About_Page');

      if (aboutPage) {
        // fetch existing About page
        getProfilePage(aboutPage.pageID)
          .then((data: ProfilePageInterface) => {
            if (canceled) return;
            setIsEdit(true);
            setPage(data);
            setIsLoading(false);
          })
          .catch((error) => {
            if (canceled) return;
            setIsLoading(false);
            console.error(error);
          });
      } else {
        setIsEdit(false);
        setIsLoading(false);
        setAboutSectionPhotos([]);
      }
    }

    return () => {
      canceled = true;
    };
  }, [currentRestaurant, isEdit]);

  useEffect(() => {
    if (isEdit && page && page?.profileSections?.length > 0 && aboutSectionPhotos == null) {
      const aboutSection: ProfileSectionInterface = page.profileSections.find(
        (section) => section.template === 'media_gallery'
      );

      if (aboutSection) {
        setAboutSectionPhotos(
          aboutSection.media?.map((_media) => ({
            mediaURL: _media?.mediaUrl,
            mediaID: _media?.mediaID,
            type: 'image'
          })) || []
        );
      } else {
        setAboutSectionPhotos([]);
      }
    } else if (isEdit && page?.profileSections?.length === 0 && aboutSectionPhotos == null) {
      setAboutSectionPhotos([]);
    }
  }, [isEdit, currentRestaurant, aboutSectionPhotos, page]);

  useEffect(() => {
    if (isEdit && page && page?.profileSections?.length > 0) {
      const cardSection = page.profileSections.find((section) => section.template === 'content_cards');

      if (cardSection) {
        const teamImgs: MenuItemMediaInterface[] = cardSection.cards
          .filter((card) => card.cardMedia.length > 0)
          .map((card) => ({
            mediaID: card.cardMedia?.[0]?.mediaID,
            mediaURL: card.cardMedia?.[0]?.mediaUrl,
            type: 'image'
          }));

        if (teamMemberImage.length === 0) {
          setTeamMemberImage(teamImgs);
        }
      }
    }
  }, [isEdit, page, teamMemberImage.length]);

  const getInitialValues = useCallback(() => {
    if (isEdit && page && page.profileSections.length > 0) {
      const teamSection: ProfileSectionInterface = page.profileSections.find(
        (section) => section.template === 'content_cards'
      );
      let teamMembersInitialValue: InferType<typeof TeamMembersSchema>[] = [];
      if (teamSection) {
        const teamMembers: InferType<typeof TeamMembersSchema>[] = teamSection.cards.map(
          (card: ProfileCardInterface) => ({
            id: card.cardID,
            teamName: card.title,
            teamTitle: card.subtitle,
            teamDescription: card.content ?? ''
          })
        );

        teamMembersInitialValue = teamMembers.length > 0 ? teamMembers : [];
      }

      const copySection: ProfileSectionInterface = page.profileSections.find((section) => section.template === 'copy');

      return {
        sectionName: copySection?.title,
        restaurantDescription: copySection?.content,
        teamMembers: teamMembersInitialValue
      };
    }

    return RestaurantAboutPageSchema.cast({
      sectionName: '',
      restaurantDescription: '',
      teamMembers: []
    });
  }, [isEdit, page]);

  const handleOnSubmit = async (value: InferType<typeof RestaurantAboutPageSchema>) => {
    if (RestaurantAboutPageSchema.isValidSync(value)) {
      if (isEdit) {
        displayLoading({
          dispatch: asyncDispatch,
          message: 'Editing Restaurant About Page...'
        });

        try {
          if (page.isHidden) {
            await editProfilePage({
              pageID: page.pageID,
              isHidden: false
            });
          }

          let gallerySectionID: number = page.profileSections.find(
            (section) => section.template === 'media_gallery'
          )?.sectionID;

          if (!gallerySectionID) {
            const createdGallerySection: CreateProfileSectionResponseInterface = await createProfileSection({
              pageID: page.pageID,
              name: 'About Media Gallery Section',
              template: 'media_gallery',
              isHidden: false
            });
            gallerySectionID = createdGallerySection.sectionID;
          }

          await linkMediasToProfileSection({
            sectionID: gallerySectionID,
            mediaIDs: aboutSectionPhotos?.map((photo) => photo?.mediaID)
          });

          const copySection: ProfileSectionInterface = page.profileSections.find(
            (section) => section.template === 'copy'
          );
          if (copySection) {
            await editProfileSection({
              sectionID: copySection.sectionID,
              title: value.sectionName,
              content: value.restaurantDescription,
              template: 'copy',
              isHidden: false
            });
          } else {
            await createProfileSection({
              pageID: page.pageID,
              name: 'About Copy Section',
              title: value.sectionName,
              content: value.restaurantDescription,
              template: 'copy',
              isHidden: false
            });
          }

          if (value.teamMembers.length > 0) {
            let teamSectionID: number;

            const teamSection: ProfileSectionInterface = page.profileSections?.find(
              (section) => section.template === 'content_cards'
            );
            if (!teamSection) {
              const createdTeamSection: CreateProfileSectionResponseInterface = await createProfileSection({
                pageID: page.pageID,
                name: 'About Team Section',
                title: 'Team',
                template: 'content_cards',
                isHidden: false
              });
              teamSectionID = createdTeamSection.sectionID;
            } else {
              teamSectionID = teamSection.sectionID;
            }

            const cardsPromises = value.teamMembers.map(
              async (teamMember: InferType<typeof TeamMembersSchema>, index: number) => {
                let cardID: number;
                if (teamMember.id > 0) {
                  cardID = teamMember.id;
                  await editProfileSectionCard({
                    cardID,
                    title: teamMember.teamName,
                    content: teamMember.teamDescription,
                    subtitle: teamMember.teamTitle
                  });
                } else {
                  const createdCard = await createProfileSectionCard({
                    sectionID: teamSectionID,
                    title: teamMember.teamName,
                    content: teamMember.teamDescription,
                    subtitle: teamMember.teamTitle
                  });

                  cardID = createdCard.cardID;
                }
                await updateMediaForProfileSectionCard({
                  cardID,
                  mediaID: teamMemberImage[index].mediaID
                });
              }
            );

            await Promise.all(cardsPromises);
          }

          // fetch updated About page
          getProfilePage(page.pageID).then((data: ProfilePageInterface) => {
            if (!isEdit) {
              setIsEdit(true);
            }
            setPage(data);
          });
          toast.success('Successfully Updated!', {
            position: toast.POSITION.TOP_RIGHT
          });
        } catch (error) {
          toast.error('Error Updating About Page. If this issue keeps occuring please reach out to the TapTab team.', {
            position: toast.POSITION.TOP_RIGHT
          });
          console.log(error);
        } finally {
          hideLoading(asyncDispatch);
        }
      } else {
        displayLoading({
          dispatch: asyncDispatch,
          message: 'Creating Restaurant About Page...'
        });

        try {
          const sectionsToCreate: Omit<CreateProfileSectionRequestInterface, 'pageID'>[] = [
            {
              name: 'About Media Gallery Section',
              template: 'media_gallery',
              isHidden: false
            },
            {
              name: 'About Copy Section',
              title: value.sectionName,
              content: value.restaurantDescription,
              template: 'copy',
              isHidden: false
            }
          ];

          if (value.teamMembers?.length > 0) {
            sectionsToCreate.push({
              name: 'About Team Section',
              title: 'Team',
              template: 'content_cards',
              isHidden: false
            });
          }

          const createdAboutPage = await createProfilePage({
            name: 'About_Page',
            urlPath: 'about-us',
            navLink: 'About Us',
            isHidden: false,
            profileSections: sectionsToCreate
          });

          if (aboutSectionPhotos.length > 0) {
            const gallerySection: CreateProfileSectionResponseInterface = createdAboutPage.profileSections.find((section) => section.template === "media_gallery")
            const aboutMediaPromises = aboutSectionPhotos.map((photo) =>
              linkMediasToProfileSection({
                sectionID: gallerySection.sectionID,
                mediaIDs: [photo.mediaID]
              })
            );
            await Promise.all(aboutMediaPromises);
          }

          const cardsSection: CreateProfileSectionResponseInterface = createdAboutPage.profileSections.find((section) => section.template === "content_cards")

          const cardsPromises = value.teamMembers.map((teamMember: InferType<typeof TeamMembersSchema>) =>
            createProfileSectionCard({
              sectionID: cardsSection.sectionID,
              title: teamMember.teamName,
              content: teamMember.teamDescription,
              subtitle: teamMember.teamTitle
            })
          );

          const cards: CreateProfileSectionCardResponseInterface[] = await Promise.all(cardsPromises);

          const mediaPromises = cards.map((card: CreateProfileSectionCardResponseInterface, index: number) =>
            updateMediaForProfileSectionCard({
              cardID: card.cardID,
              mediaID: teamMemberImage[index].mediaID
            })
          );

          await Promise.all(mediaPromises);

          // fetch updated About page
          getProfilePage(createdAboutPage.pageID).then((data: ProfilePageInterface) => {
            setIsEdit(true);
            setPage(data);
          });
          toast.success('Successfully Created!', {
            position: toast.POSITION.TOP_RIGHT
          });
        } catch (error) {
          toast.error('Error Creating About Page. If this issue keeps occuring please reach out to the TapTab team.', {
            position: toast.POSITION.TOP_RIGHT
          });
          console.error('Error creating restaurant about page:', error);
        } finally {
          hideLoading(asyncDispatch);
        }
      }
    }
  };

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema: RestaurantAboutPageSchema,
    enableReinitialize: true,
    onSubmit: handleOnSubmit,
    validateOnBlur: true
  });

  const handleCreateTeam = () => {
    formik.setFieldValue('teamMembers', [TeamMembersSchema.cast({}, { assert: false })]);
  };

  const handleSkipCreateTeam = () => {
    formik.setFieldValue('teamMembers', []);
  };

  const handleTeamMemberImageUpload = (image: MenuItemMediaInterface, index: number) => {
    const updatedTeamMemberImage = [...teamMemberImage];
    updatedTeamMemberImage[index] = image;
    setTeamMemberImage(updatedTeamMemberImage);
  };

  const validateTeamName = (teamName: string): boolean => {
    let isValid = true;
    if (teamName?.trim() === '') {
      isValid = false;
    }
    return isValid;
  };

  const validateTeamTitle = (teamTitle: string): boolean => {
    let isValid = true;
    if (teamTitle?.trim() === '') {
      isValid = false;
    }
    return isValid;
  };

  const handleAddTeamMember = () => {
    const index = formik.values.teamMembers.length - 1;

    const touch = [];
    for (let i = 0; i < index + 1; i++) {
      touch.push({ teamName: true, teamTitle: true, teamDescription: true });
    }
    formik.setTouched({ teamMembers: touch });

    if (
      validateTeamName(formik.values.teamMembers[index].teamName) &&
      validateTeamTitle(formik.values.teamMembers[index].teamTitle)
    ) {
      formik.setFieldValue(`teamMembers.${index + 1}`, TeamMembersSchema.cast({}, { assert: false }));
    } else {
      formik.setFieldError(`teamMembers.${index + 1}.teamName`, `Name is required`);
      formik.setFieldError(`teamMembers.${index + 1}.teamTitle`, `Title is required`);
    }
  };

  const handleDeleteTeamMember = async (index: number) => {
    const { teamMembers } = formik.values;

    const cards = [...teamMembers];
    const deletedTeamMemberID: number = cards.splice(index, 1)[0].id;

    if (deletedTeamMemberID > 0) {
      try {
        await deleteProfileSectionCard({ cardID: deletedTeamMemberID });
      } catch (error) {
        toast.error('Error Deleting Team Member. If this issue keeps occuring please reach out to the TapTab team.', {
          position: toast.POSITION.TOP_RIGHT
        });
        console.error(error);
      }
    }
    const images = teamMemberImage.slice();
    images.splice(index, 1);
    setTeamMemberImage(images);
    await formik.setFieldValue('teamMembers', cards);
  };

  if (isLoading && aboutSectionPhotos != null) {
    return <div>Loading...</div>;
  }

  // new uploader logic
  const handleMediaLibrarySelection = (selectedMedia: MediaLibraryInterface[]) => {
    const _media = aboutSectionPhotos.slice();
    const newMedia = selectedMedia.slice();

    handleMediaUpdate(
      _media.concat(
        newMedia.map(
          (mediaLibrary) =>
            ({
              mediaID: mediaLibrary.mediaID,
              mediaURL: mediaLibrary.mediaUrl,
              type: mediaLibrary.type
            } as MenuItemMediaInterface)
        )
      )
    );
  };

  const handleMediaLibraryRemoval = (mediaID: number) => {
    const _media = aboutSectionPhotos.slice();
    const idx = _media.findIndex((mediaToDelete) => mediaToDelete?.mediaID === mediaID);
    _media.splice(idx, 1);
    handleMediaUpdate(_media);
  };

  const handleCloseMediaLibraryModal = () => {
    setOpenMediaLibraryModal(false);
  };
  return (
    <div className="about-page-container">
      <WizardHeader className="about-page-header">
        <div className="about-page-header-content">
          <h1 className="about-page-header-title">ABOUT</h1>
        </div>
      </WizardHeader>
      <form className="about-page-content" onSubmit={formik.handleSubmit}>
        <div className="restaurant-about-page">
          <Input
            name="sectionName"
            label="ABOUT SECTION NAME"
            className="about-page-name-input"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.sectionName}
            touched={formik.touched?.sectionName}
            error={formik.errors?.sectionName}
          />
          <div className="about-page-about-section-photos">
            <Label label="ABOUT SECTION PHOTOS" className="about-page-about-section-label" />
            <p className="about-page-photo-prompt">
              Choose up to 10 photos for your About page. Each photo should be at least 100 px wide and 100 px tall.
            </p>
            <div className="media-container">
              <Button className="select-media-button" onClick={() => setOpenMediaLibraryModal(true)}>
                <img className="media-upload-icon" src={imageDisplay} alt="Icon indicating upload" />
                <p>Upload Media</p>
              </Button>
              <MediaPreview media={aboutSectionPhotos ?? []} onUpdate={handleMediaUpdate} multiple />
              {openMediaLibraryModal && (
                <MediaLibraryModal
                  selectedMediaIDs={aboutSectionPhotos?.map((_media) => _media?.mediaID) ?? []}
                  onSelect={handleMediaLibrarySelection}
                  onRemove={handleMediaLibraryRemoval}
                  onClose={handleCloseMediaLibraryModal}
                  mediaTypeOverride="image"
                />
              )}
            </div>
          </div>
          <div className="about-page-description-input">
            <TextArea
              name="restaurantDescription"
              label={<span>ABOUT YOUR RESTAURANT</span>}
              className="about-page-description-input"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.restaurantDescription}
              touched={formik.touched?.restaurantDescription}
              error={formik.errors?.restaurantDescription}
            />
          </div>
          <div className="about-page-section-team">
            <Label
              label={
                <span>
                  TEAM <i>(Optional)</i>
                </span>
              }
            />
            <p className="about-page-photo-prompt">
              Choose your favorite restaurant team member photos for your profile photo. This photo will be displayed in
              TapTab&apos;s restaurant list, on your profile screen & atop your menu.
            </p>
          </div>
          {formik.values.teamMembers.length === 0 && (
            <Button className="about-page-create-team-button" onClick={handleCreateTeam}>
              <PlusIcon />
              <span>CREATE TEAM</span>
            </Button>
          )}
          <div className="new-team-member-container" style={{ marginBottom: '-35px' }}>
            {formik.values.teamMembers.length > 0 &&
              formik.values.teamMembers.map((member: any, index: any) => (
                <RestaurantAboutTeamMember
                  key={member.id}
                  formik={formik}
                  teamMemberImage={teamMemberImage[index]}
                  handleTeamMemberImageUpload={(image: MenuItemMediaInterface) =>
                    handleTeamMemberImageUpload(image, index)
                  }
                  handleDeleteTeamMember={() => handleDeleteTeamMember(index)}
                  isLastTeamMember={index === formik.values.teamMembers.length - 1}
                  index={index}
                  handleSkipCreateTeam={handleSkipCreateTeam}
                  handleAddTeamMember={handleAddTeamMember}
                />
              ))}
          </div>
          <div className="about-page-button">
            <Button className="about-page-submit-button" submit>
              SAVE
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AboutPage;
