import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { InferType, array, object, string, number } from 'yup';
import { toast } from 'react-toastify';
import Button from '../../../components/common/Button';
import { PlusIcon } from '../../../assets/svgs/icons';
import { displayLoading, hideLoading, useAsyncContext } from '../../../contexts/AsyncContext';
import RestaurantPressArticle from '../../../components/RestaurantPressArticle/RestaurantPressArticle';
import WizardHeader from '../../../components/WizardHeader/WizardHeader';
import { useMediaLibraryContext } from '../../../contexts/MediaLibraryContext';
import { useRestaurantContext } from '../../../contexts/RestaurantContext';
import { createProfilePage, editProfilePage, getProfilePage } from '../../../api/profilePages';
import {
  createProfileSection,
  createProfileSectionCard,
  deleteProfileSection,
  deleteProfileSectionCard,
  editProfileSectionCard,
  linkMediasToProfileSection,
  updateMediaForProfileSectionCard
} from '../../../api/profileSections';
import {
  CreateProfileSectionCardResponseInterface,
  CreateProfileSectionResponseInterface,
  ProfileSectionInterface
} from '../../../types/ProfileSectionInterface';
import { ProfilePageInterface } from '../../../types/ProfilePageInterface';
import { Label } from '../../../components/common/Form';
import imageDisplay from '../../../assets/images/ImageDisplay.png';
import MediaPreview from '../../../components/MediaPreview';
import MediaLibraryModal from '../../../components/MediaLibraryModal';
import { MediaLibraryInterface, MenuItemMediaInterface } from '../../../types/MediaInterface';

export const PressImageSchema = object({
  mediaID: number().required('Image is required'),
  mediaURL: string().default(''),
  type: string().default('image')
});

export const ArticleSchema = object({
  id: number().default(0),
  articleName: string().default('').required('Article Name is required.'),
  publication: string().default(''),
  link: string().default('').required('Link is required.'),
  articleDescription: string().default(''),
  image: PressImageSchema
});

export const PressPageSchema = object({
  articles: array()
    .of(ArticleSchema)
    .default([ArticleSchema.cast({ articleName: '', publication: '', link: '', articleDescription: '' })])
});

const PressPage = () => {
  const [isEdit, setIsEdit] = useState<boolean>(null);
  const [openMediaLibraryModal, setOpenMediaLibraryModal] = useState<boolean>(false);
  const [page, setPage] = useState<ProfilePageInterface>();
  const [pressPhotos, setPressPhotos] = useState<MenuItemMediaInterface[]>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { dispatch: asyncDispatch } = useAsyncContext();
  const { loadMedia } = useMediaLibraryContext();
  const { currentRestaurant } = useRestaurantContext();

  useEffect(() => {
    // only want to load the restaurants user media if manager page is being utilized
    // call to load media on initial load
    loadMedia();
  }, [loadMedia]);

  useEffect(() => {
    if (isEdit && page && page?.profileSections?.length > 0 && pressPhotos == null) {
      const photoSection: ProfileSectionInterface = page.profileSections.find(
        (section) => section.template === 'media_gallery'
      );

      if (photoSection) {
        setPressPhotos(
          photoSection.media?.map((_media) => ({
            mediaURL: _media?.mediaUrl,
            mediaID: _media?.mediaID,
            type: 'image'
          })) || []
        );
      } else {
        setPressPhotos([]);
      }
    } else if (isEdit && page?.profileSections?.length === 0 && pressPhotos == null) {
      setPressPhotos([]);
    }
  }, [isEdit, currentRestaurant, pressPhotos, page]);

  useEffect(() => {
    let canceled = false;
    if (currentRestaurant != null && isEdit == null) {
      const pressPage = currentRestaurant.pages.find((_page) => _page.name === 'Press_Page');

      if (pressPage) {
        // fetch existing press page
        getProfilePage(pressPage.pageID)
          .then((data: ProfilePageInterface) => {
            if (canceled) return;
            setIsEdit(true);
            setPage(data);
            setIsLoading(false);
          })
          .catch((error) => {
            if (canceled) return;
            setIsLoading(false);
            console.error(error);
          });
      } else {
        setIsEdit(false);
        setIsLoading(false);
        setPressPhotos([]);
      }
    }

    return () => {
      canceled = true;
    };
  }, [currentRestaurant, isEdit]);

  const handleMediaUpdate = (mediaInvolved: MenuItemMediaInterface[]) => {
    setPressPhotos(mediaInvolved);
  };

  const handleMediaLibrarySelection = (selectedMedia: MediaLibraryInterface[]) => {
    const _media = pressPhotos.slice();
    const newMedia = selectedMedia.slice();

    handleMediaUpdate(
      _media.concat(
        newMedia.map(
          (mediaLibrary) =>
            ({
              mediaID: mediaLibrary.mediaID,
              mediaURL: mediaLibrary.mediaUrl,
              type: mediaLibrary.type
            } as MenuItemMediaInterface)
        )
      )
    );
  };

  const handleMediaLibraryRemoval = (mediaID: number) => {
    const _media = pressPhotos.slice();
    const idx = _media.findIndex((mediaToDelete) => mediaToDelete?.mediaID === mediaID);
    _media.splice(idx, 1);
    handleMediaUpdate(_media);
  };

  const handleCloseMediaLibraryModal = () => {
    setOpenMediaLibraryModal(false);
  };

  const getInitialValues = useCallback(() => {
    if (isEdit && page) {
      const articleSection: ProfileSectionInterface = page.profileSections?.find(
        (section) => section.template === 'interactive_content_cards'
      );

      const articles: InferType<typeof ArticleSchema>[] = articleSection?.cards?.map((card) => ({
        id: card.cardID,
        articleName: card.title,
        publication: card.subtitle,
        link: card.linkURL ?? '',
        articleDescription: card.content ?? '',
        image: {
          mediaID: card.cardMedia?.[0]?.mediaID,
          mediaURL: card.cardMedia?.[0]?.mediaUrl,
          type: 'image'
        }
      }));

      if (articles?.length > 0) {
        return { articles };
      }
    }

    return PressPageSchema.cast({
      articles: [ArticleSchema.cast({}, { assert: false })]
    });
  }, [isEdit, page]);

  const handleOnSubmit = async (value: InferType<typeof PressPageSchema>) => {
    if (PressPageSchema.isValidSync(value)) {
      if (isEdit) {
        displayLoading({ dispatch: asyncDispatch, message: 'Editing Press Page...' });

        try {
          if (page.isHidden) {
            await editProfilePage({
              pageID: page.pageID,
              isHidden: false
            });
          }

          let gallerySectionID: number = page.profileSections.find(
            (section) => section.template === 'media_gallery'
          )?.sectionID;

          if (!gallerySectionID) {
            const createdGallerySection: CreateProfileSectionResponseInterface = await createProfileSection({
              pageID: page.pageID,
              name: 'Press Media Gallery Section',
              template: 'media_gallery',
              isHidden: false
            });
            gallerySectionID = createdGallerySection.sectionID;
          }

          await linkMediasToProfileSection({
            sectionID: gallerySectionID,
            mediaIDs: pressPhotos?.map((photo) => photo?.mediaID)
          });

          let cardSection: ProfileSectionInterface;
          if (page.profileSections?.length === 0) {
            const card: CreateProfileSectionResponseInterface = await createProfileSection({
              pageID: page.pageID,
              name: 'Press Article Section',
              title: 'Press',
              template: 'interactive_content_cards'
            });
            cardSection = { ...card, media: [], cards: [] } as ProfileSectionInterface;
          } else {
            cardSection = page.profileSections.find((section) => section.template === 'interactive_content_cards');
          }

          const cardsPromises = value.articles.map(async (article: InferType<typeof ArticleSchema>) => {
            if (article.id > 0) {
              await editProfileSectionCard({
                cardID: article.id,
                title: article.articleName,
                content: article.articleDescription,
                subtitle: article.publication,
                linkURL: article.link
              });

              await updateMediaForProfileSectionCard({
                cardID: article.id,
                mediaID: article.image.mediaID
              });
            } else {
              const createdCard = await createProfileSectionCard({
                sectionID: cardSection.sectionID,
                title: article.articleName,
                subtitle: article.publication,
                linkURL: article.link,
                ...(article.articleDescription && { content: article.articleDescription })
              });

              await updateMediaForProfileSectionCard({
                cardID: createdCard.cardID,
                mediaID: article.image.mediaID
              });
            }
          });

          await Promise.all(cardsPromises);

          // fetch updated press page
          getProfilePage(page.pageID).then((data: ProfilePageInterface) => {
            if (!isEdit) {
              setIsEdit(true);
            }
            setPage(data);
          });
          toast.success('Successfully Updated!', {
            position: toast.POSITION.TOP_RIGHT
          });
        } catch (error) {
          toast.error('Error Updating Press Page. If this issue keeps occuring please reach out to the TapTab team.', {
            position: toast.POSITION.TOP_RIGHT
          });
          console.error(error);
        } finally {
          hideLoading(asyncDispatch);
        }
      } else {
        displayLoading({ dispatch: asyncDispatch, message: 'Creating Press Page...' });

        try {
          const createdPressPage = await createProfilePage({
            name: 'Press_Page',
            urlPath: 'press',
            navLink: 'Press',
            isHidden: false,
            profileSections: [
              {
                name: 'Press Media Gallery Section',
                template: 'media_gallery',
                isHidden: false
              },
              {
                name: 'Press Article Section',
                title: 'Press',
                template: 'interactive_content_cards'
              }
            ]
          });

          if (pressPhotos.length > 0) {
            const gallerySection: CreateProfileSectionResponseInterface = createdPressPage.profileSections.find(
              (section) => section.template === 'media_gallery'
            );
            const pressMediaPromises = pressPhotos.map((photo) =>
              linkMediasToProfileSection({
                sectionID: gallerySection.sectionID,
                mediaIDs: [photo.mediaID]
              })
            );
            await Promise.all(pressMediaPromises);
          }

          const cardSection: CreateProfileSectionResponseInterface = createdPressPage.profileSections.find(
            (section) => section.template === 'interactive_content_cards'
          );
          const cardsPromises = value.articles.map((article: InferType<typeof ArticleSchema>) =>
            createProfileSectionCard({
              sectionID: cardSection.sectionID,
              title: article.articleName,
              subtitle: article.publication,
              linkURL: article.link,
              ...(article.articleDescription && { content: article.articleDescription })
            }));

          const cards: CreateProfileSectionCardResponseInterface[] = await Promise.all(cardsPromises);

          const mediaPromises = cards.map((card: CreateProfileSectionCardResponseInterface, index: number) =>
            updateMediaForProfileSectionCard({
              cardID: card.cardID,
              mediaID: value.articles?.[index]?.image?.mediaID
            })
          );

          await Promise.all(mediaPromises);
          // fetch created press page
          getProfilePage(createdPressPage.pageID).then((data: ProfilePageInterface) => {
            setIsEdit(true);
            setPage(data);
          });
          toast.success('Successfully Created!', {
            position: toast.POSITION.TOP_RIGHT
          });
        } catch (error) {
          toast.error('Error Creating Press Page. If this issue keeps occuring please reach out to the TapTab team.', {
            position: toast.POSITION.TOP_RIGHT
          });
          console.error(error);
        } finally {
          hideLoading(asyncDispatch);
        }
      }
    }
  };

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema: PressPageSchema,
    enableReinitialize: true,
    onSubmit: handleOnSubmit,
    validateOnBlur: true
  });

  const validateArticleName = (articleName: string): boolean => {
    let isValid = true;
    if (articleName?.trim() === '') {
      isValid = false;
    }
    return isValid;
  };

  const validateArticleLink = (link: string): boolean => {
    let isValid = true;
    if (link?.trim() === '') {
      isValid = false;
    }
    return isValid;
  };

  const handleAddArticle = () => {
    const index = formik.values.articles.length - 1;
    const touch = [];
    for (let i = 0; i < index + 1; i++) {
      if (
        validateArticleName(formik.values.articles[index].articleName) &&
        validateArticleLink(formik.values.articles[index].link)
      ) {
        formik.setFieldValue(
          `articles.${index + 1}`,
          ArticleSchema.cast({ articleName: '', publication: '', link: '', articleDescription: '' }, { assert: false })
        );
      } else {
        formik.setFieldError(`articles.${index + 1}.articleName`, `Article Name is required`);
        formik.setFieldError(`articles.${index + 1}.link`, `Link is required`);
      }

      touch.push({
        articleName: true,
        publication: true,
        link: true,
        articleDescription: true,
        image: true
      });
      formik.setTouched({ articles: touch });

    }
  };

  const handleDeleteArticle = async (index: number) => {
    const { articles } = formik.values;
    const cards: InferType<typeof ArticleSchema>[] = [...articles];
    cards.reverse();

    const deletedArticleID: number = cards.splice(index, 1)[0].id;

    if (deletedArticleID > 0) {
      try {
        await deleteProfileSectionCard({ cardID: deletedArticleID });
      } catch (error) {
        toast.error('Error Deleting This Article. If this issue keeps occuring please reach out to the TapTab team.', {
          position: toast.POSITION.TOP_RIGHT
        });
        console.error(error);
      }
    }

    // inverse cards when setting formik values
    cards.reverse();
    await formik.setFieldValue('articles', cards);
  };

  const handleDeleteClicked = async () => {
    if (isEdit) {
      try {
        await editProfilePage({
          pageID: page.pageID,
          isHidden: true
        });

        const sectionPromises = page.profileSections.map(async ({ sectionID }: ProfileSectionInterface) => {
          if (sectionID && sectionID > 0) {
            await deleteProfileSection({ sectionID });
          }
        });

        if (sectionPromises?.length > 0) {
          await Promise.all(sectionPromises);
        }
      } catch (error) {
        console.error(error);
      }
    }

    setPage({ ...page, profileSections: [] } as ProfilePageInterface);
    formik.resetForm();
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="press-page-container">
      <WizardHeader className="press-page-header">
        <div className="press-page-header-content">
          <h1 className="press-page-header-title">PRESS</h1>
        </div>
      </WizardHeader>
      <form className="press-page-content" onSubmit={formik.handleSubmit}>
        <div className="restaurant-press-page">
          <div className="press-page-photos">
            <Label label="PRESS PHOTOS" className="press-page-label" />
            <p className="press-page-photo-prompt">
              Choose up to 10 photos for your Press page. Each photo should be at least 100 px wide and 100 px tall.
            </p>
            <div className="media-container">
              <Button className="select-media-button" onClick={() => setOpenMediaLibraryModal(true)}>
                <img className="media-upload-icon" src={imageDisplay} alt="Icon indicating upload" />
                <p>Upload Media</p>
              </Button>
              <MediaPreview media={pressPhotos ?? []} onUpdate={handleMediaUpdate} multiple />
              {openMediaLibraryModal && (
                <MediaLibraryModal
                  selectedMediaIDs={pressPhotos?.map((_media) => _media?.mediaID) ?? []}
                  onSelect={handleMediaLibrarySelection}
                  onRemove={handleMediaLibraryRemoval}
                  onClose={handleCloseMediaLibraryModal}
                  mediaTypeOverride="image"
                />
              )}
            </div>
          </div>
          <h2 className="restaurant-press-page-press-title">PRESS</h2>
          <p className="press-page-prompt">
            Add articles about your restaurant that will be displayed on the press page.
          </p>
          <div className="press-articles-container">
            {formik.values.articles.map((article: any, index: any) => (
              <RestaurantPressArticle
                key={article.id}
                formik={formik}
                handleDeleteArticle={() => handleDeleteArticle(index)}
                showDeleteButton={formik.values.articles.length > 1}
                isLastArticle={index === formik.values.articles.length - 1}
                index={index}
              />
            ))}
          </div>
          <Button className="press-page-add-article" onClick={handleAddArticle}>
            <PlusIcon />
            <span>ADD ARTICLE</span>
          </Button>
          <div className="press-page-buttons">
            <Button
              className="press-page-delete-button"
              isDisabled={!isEdit || page?.profileSections?.length === 0}
              onClick={handleDeleteClicked}
            >
              DELETE
            </Button>
            <Button className="press-page-submit-button" submit>
              SAVE
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PressPage;
